'use strict';

Object.defineProperty(exports, "__esModule", {
	value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _lottieWeb = require('lottie-web');

var _lottieWeb2 = _interopRequireDefault(_lottieWeb);

var _underscore = require('underscore');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var self = void 0;
var throttledOnScroll = function throttledOnScroll() {
	(0, _underscore.throttle)(self.onScroll(), 100);
};

var Showcase = function () {
	function Showcase() {
		_classCallCheck(this, Showcase);

		this.$container = document.getElementById('home-showcase');
		if (this.$container) {
			self = this;
			this.addBlockFade();
		}
	}

	_createClass(Showcase, [{
		key: 'addMedia',
		value: function addMedia($elem) {
			var $lottie = $elem.querySelector('.lottie');
			if ($lottie) {
				// Play Lottie on hover
				$elem.addEventListener('mouseenter', function () {
					_lottieWeb2.default.play($lottie.dataset.src);
				});
			}
		}
	}, {
		key: 'initMedia',
		value: function initMedia($elem) {
			var $lottie = $elem.querySelector('.lottie');
			if ($lottie) {
				// A lottie animation exists. Load it.
				var l = _lottieWeb2.default.loadAnimation({
					container: $lottie,
					renderer: 'svg',
					loop: false,
					autoplay: false,
					name: $lottie.dataset.src,
					path: $lottie.dataset.src
				});
				l.addEventListener('complete', function () {
					// return to start of animation so it can be replayed
					l.goToAndStop(0);
				});
			}

			Array.prototype.forEach.call($elem.querySelectorAll('video'), function (elem) {
				elem.play();
			});
		}
	}, {
		key: 'addBlockFade',
		value: function addBlockFade() {
			var _this = this;

			var windowTop = window.scrollY + window.innerHeight;
			this.blocks = [];
			Array.prototype.forEach.call(this.$container.querySelectorAll('a'), function (elem, i) {
				var top = elem.offsetTop;
				if (top > windowTop) {
					// Block is not yet in view. Add appearance animation.

					// Set initial offset
					// Odd blocks have a larger offset as their initial position
					// includes an existing 50% offset.
					var offset = 20;
					if (i % 2) {
						offset = 70;
					}
					elem.style.opacity = 0;
					elem.style.transform = 'translateY(' + offset + '%)';

					_this.addMedia(elem);
					_this.blocks.push(elem);
				} else {
					// Block is in view
					_this.addMedia(elem);
					_this.initMedia(elem);
				}
			});

			if (this.blocks.length) {
				// Monitor document scroll
				document.addEventListener('scroll', throttledOnScroll);
			}
		}
	}, {
		key: 'onScroll',
		value: function onScroll() {
			var _this2 = this;

			var windowTop = window.scrollY + window.innerHeight;
			if (this.blocks.length) {
				Array.prototype.forEach.call(this.blocks, function (elem, i) {
					// Check to see if block is now in view
					if (windowTop > elem.offsetTop) {
						_this2.initMedia(elem);

						// Make item visible
						elem.style.opacity = 1;
						elem.style.transform = '';

						// Remove item from array of blocks we're monitoring.
						_this2.blocks.splice(i, 1);
					}
				});
			} else {
				// No blocks left. Remove event handler
				document.removeEventListener('scroll', throttledOnScroll);
			}
		}
	}]);

	return Showcase;
}();

exports.default = Showcase;
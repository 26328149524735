'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.capabilityHeroBgScroll = exports.extendedHeroBgScroll = exports.extendedHero = undefined;

var _slicedToArray = function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"]) _i["return"](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError("Invalid attempt to destructure non-iterable instance"); } }; }();

var _lottieWeb = require('lottie-web');

var _lottieWeb2 = _interopRequireDefault(_lottieWeb);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var extendedHero = function extendedHero() {
    //run lottie animation as background image
    var $extendedHero = document.querySelector('.m-extended-hero .lottie');
    if ($extendedHero) {
        var l = _lottieWeb2.default.loadAnimation({
            container: $extendedHero,
            renderer: 'svg',
            loop: true,
            autoplay: true,
            path: $extendedHero.dataset.src
        });
    }
};

var extendedHeroBgScroll = function extendedHeroBgScroll() {
    //Change background color of the page based upon the Capabilities body coming into viewport
    if (document.querySelector('.m-extended-hero')) {
        var hero = document.querySelector('.m-extended-hero');

        var heroColor = hero.getAttribute('data-bgcolor');

        var observer = new IntersectionObserver(function (_ref) {
            var _ref2 = _slicedToArray(_ref, 1),
                entry = _ref2[0];

            var color = entry.isIntersecting ? heroColor : '#fff';
            //if the hero enters the viewport, set the page background color to the hero color, when it leaves set the background color to white
            var lottie = document.querySelector('.lottie');
            if (lottie) {
                entry.isIntersecting ? lottie.style.opacity = 1 : lottie.style.opacity = 0;
            }

            document.querySelector('#content').style.background = color;
        }, { threshold: [0.5] });

        observer.observe(hero);
    }
};

var capabilityHeroBgScroll = function capabilityHeroBgScroll() {
    if (document.querySelector('.m-capability-hero')) {
        var hero = document.querySelector('.m-capability-hero');
        var heroColor = hero.getAttribute('data-bgcolor');

        var overlay = document.querySelector('#overlay');
        if (overlay) {
            overlay.style.background = heroColor;
        }

        var observer = new IntersectionObserver(function (_ref3) {
            var _ref4 = _slicedToArray(_ref3, 1),
                entry = _ref4[0];

            var pattern = document.querySelector('.pattern');
            if (pattern && overlay) {
                var opacity = Math.pow(entry.intersectionRatio, 6); // Faster fade out
                pattern.style.opacity = opacity * 0.9;
                overlay.style.opacity = opacity * 0.9;
            }
        }, { threshold: Array.from({ length: 100 }, function (_, i) {
                return i / 100;
            }) });

        observer.observe(hero);
    }
};

exports.extendedHero = extendedHero;
exports.extendedHeroBgScroll = extendedHeroBgScroll;
exports.capabilityHeroBgScroll = capabilityHeroBgScroll;
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _underscore = require("underscore");

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var ServicesImage = function () {
  function ServicesImage() {
    _classCallCheck(this, ServicesImage);

    this.$bubbles = document.querySelectorAll(".foreground");
    this.$backgrondLeft = document.getElementsByClassName("background")[0].style.left;
    this.scroller();
  }

  _createClass(ServicesImage, [{
    key: "scroller",
    value: function scroller() {
      var _this = this;

      var windowHeight = window.innerHeight;
      var windowWidth = window.innerWidth;

      window.addEventListener("resize", (0, _underscore.throttle)(function (e) {
        windowHeight = window.innerHeight;
        windowWidth = window.innerWidth;
        Array.prototype.forEach.call(_this.$bubbles, function (elem) {
          if (windowWidth >= 1201) {
            elem.style.left = 0;
          } else {
            elem.style.top = 0;
            elem.style.left = windowWidth >= 768 ? windowWidth * 8.3 / 100 + "px" : 0;
          }
        });
      }));

      document.addEventListener("scroll", function (e) {
        //LEFT TO RIGHT

        if (windowWidth < 1200) {
          var gridLeft = windowWidth >= 768 ? windowWidth * 8.3 / 100 : 0;
          Array.prototype.forEach.call(_this.$bubbles, function (elem) {
            var bounding = elem.getBoundingClientRect();
            var parentBounding = elem.parentNode.getBoundingClientRect();

            if (bounding.bottom >= 300 && bounding.bottom < windowHeight && !elem.classList.contains("-complete")) {
              var left = gridLeft + Math.round(windowHeight / 2 - bounding.bottom) + 360;

              if (left >= gridLeft && left < parentBounding.width * 0.75 - bounding.width) {
                elem.style.left = left + "px";
              } else if (left >= gridLeft && left >= parentBounding.width * 0.75 - bounding.width) {
                elem.style.left = parentBounding.width * 0.75 - bounding.width + "px";
                elem.classList.add("-complete");
              } else {
                elem.style.left = gridLeft + "px";
              }
            }
          });
        }

        //TOP TO BOTTOM
        else {
            Array.prototype.forEach.call(_this.$bubbles, function (elem) {
              var bounding = elem.getBoundingClientRect();
              if (bounding.bottom >= 300 && bounding.bottom < windowHeight && !elem.classList.contains("-complete")) {
                var bottom = Math.round(windowHeight / 2 - bounding.bottom) + 20;

                if (bottom > 0 && bottom <= bounding.height * 0.5) {
                  elem.style.top = bottom + "px";
                } else if (bottom > bounding.height * 0.5) {
                  elem.style.top = bounding.height * 0.5 + "px";
                  elem.classList.add("-complete");
                } else {
                  elem.style.top = 0;
                }
              }
            });
          }
      });
    }
  }]);

  return ServicesImage;
}();

exports.default = ServicesImage;
'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.navInit = navInit;
function navInit() {
    var bodyClass = 'show-menu';
    var collapsedClass = 'collapsed';
    var body = document.querySelector('body');
    var toggleButton = document.querySelector('.nav-toggle');

    var parent = toggleButton.parentElement;
    var targetMenu = parent.querySelector(toggleButton.getAttribute('href'));

    function showMenu() {
        body.classList.add(bodyClass);
        parent.classList.remove(collapsedClass);
    }

    function hideMenu() {
        body.classList.remove(bodyClass);
        parent.classList.add(collapsedClass);
    }

    if (toggleButton) {
        toggleButton.addEventListener('click', function (event) {
            event.preventDefault();
            parent.classList.contains(collapsedClass) ? showMenu() : hideMenu();
        });
    }
}
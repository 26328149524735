"use strict";

Object.defineProperty(exports, "__esModule", {
	value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _underscore = require("underscore");

function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } else { return Array.from(arr); } }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var transitionTime = 50;

var HomeHero = function () {
	function HomeHero() {
		_classCallCheck(this, HomeHero);

		this.$container = document.getElementById("home-hero");

		if (this.$container) {
			this.$introContainer = document.getElementById("home-hero-intro");
			this.$bubblesContainer = document.getElementById("home-hero-bubbles");
			this.$header = document.getElementById("header");

			this.runIntro();
		}
	}

	// Run the intro animation


	_createClass(HomeHero, [{
		key: "runIntro",
		value: function runIntro() {
			var _this = this;

			// Add bubbles to DOM
			this.addBubbles();

			// Wait before hiding intro slide
			setTimeout(function () {
				// Hide header
				_this.$header.classList.add("hidden");
				// Shrink intro container
				_this.$introContainer.style.transform = "scale(0)";
				// Shrink bubbles container
				_this.$bubblesContainer.style.transform = "scale(1)";
				// Wait for transition to finish
				_this.$introContainer.addEventListener('transitionend', function () {
					// Init copy cycling
					_this.cycleCopy();

					// Scale up bubble images
					_this.scaleUpBubbleImages();

					// Reveal header
					_this.$header.classList.remove('hidden');

					// keep track of whether we've seen a scroll event yet
					_this.hasScrolled = false;
				}, {
					once: true
				});
			}, 150);
		}
	}, {
		key: "cycleCopy",
		value: function cycleCopy() {
			// Radial wipe - reveal copy
			var $copy = this.$container.querySelector('.copy');
			$copy.style.clipPath = "circle(100vw)";
			$copy.addEventListener('transitionend', function () {
				$copy.style.clipPath = "none";
			}, {
				once: true
			});

			var $first = this.$container.querySelector('.copy li');
			var $active = $first;

			setInterval(function () {
				var $next = $active.nextElementSibling;
				if (!$next) {
					$next = $first;
				}

				$active.classList = '';
				$next.classList = '-active';
				$active = $next;
			}, 500);
		}
	}, {
		key: "addBubbles",
		value: function addBubbles() {
			var _this2 = this;

			// Note: z-index of copy is 1
			var data = [{
				color: 'black',
				size: '4',
				top: '29%',
				left: '20%',
				zIndex: 1,
				mobile: true
			}, {
				color: 'green',
				size: '2',
				top: '10%',
				left: '55%',
				zIndex: 1,
				mobile: false
			}, {
				color: 'grey',
				size: '2',
				top: '20%',
				left: '10%',
				zIndex: 1,
				mobile: false
			}, {
				color: 'grey',
				size: '3',
				top: '20%',
				left: '45%',
				zIndex: 1,
				mobile: false
			}, {
				color: 'grey',
				size: '1',
				top: '25%',
				left: '70%',
				zIndex: 1,
				mobile: true
			}, {
				color: 'grey',
				size: '5',
				top: '5%',
				left: '75%',
				zIndex: 1,
				mobile: false
			}, {
				color: 'grey',
				size: '3',
				top: '80%',
				left: '55%',
				zIndex: 1,
				mobile: false
			}, {
				color: 'green',
				size: '3',
				top: '70%',
				left: '-20px',
				zIndex: 1,
				mobile: true
			}, {
				color: 'grey',
				size: '2',
				top: '40%',
				left: '90%',
				zIndex: 1,
				mobile: true
			}, {
				color: 'grey',
				size: '1',
				top: '49%',
				left: '24%',
				zIndex: 1,
				mobile: false
			}, {
				color: 'grey',
				size: '6',
				top: '-10px',
				left: '16%',
				zIndex: 0,
				img: '/static/img/home-hero/hero-bubble-01',
				mobile: true
			}, {
				color: 'grey',
				size: '7',
				top: '70%',
				left: '80%',
				zIndex: 0,
				img: '/static/img/home-hero/hero-bubble-02',
				mobile: true
			}, {
				color: 'grey',
				size: '6',
				top: '70%',
				left: '5%',
				zIndex: 0,
				img: '/static/img/home-hero/hero-bubble-03',
				mobile: true
			}];

			var bubbles = [];
			var isMobile = window.innerWidth < 768;
			data.forEach(function (d) {
				if (!isMobile || d.mobile) {
					var _$bubble$classList;

					var depth = Math.floor(Math.random() * 4) + 1;

					var classes = ['e-bubble', "e-bubble--size-" + d.size];
					if (d.color) {
						classes.push("e-bubble--" + d.color);
					}

					var $bubble = document.createElement('div');
					(_$bubble$classList = $bubble.classList).add.apply(_$bubble$classList, classes);
					$bubble.style.left = d.left;
					$bubble.style.top = d.top;
					$bubble.dataset.size = d.size;
					$bubble.dataset.depth = depth;
					$bubble.style.zIndex = d.zIndex;

					if (d.img) {
						if (isMobile) {
							$bubble.innerHTML = "<div class='-cursor'><div class='-bubble'><img src='" + d.img + "-mobile.jpg' alt='Bubble' draggable='false'></div></div>";
						} else {
							$bubble.innerHTML = "<div class='-cursor'><div class='-bubble'><img src='" + d.img + ".jpg' alt='Bubble' draggable='false'></div></div>";
						}
					} else {
						$bubble.innerHTML = '<div class=\'-cursor\'><div class=\'-bubble\'></div></div>';
					}
					bubbles.push($bubble);
					_this2.$bubblesContainer.appendChild($bubble);
				}
			});

			this.$bubbles = this.$bubblesContainer.querySelectorAll('.e-bubble');
			this.addMotionEffects();
		}
	}, {
		key: "addMotionEffects",
		value: function addMotionEffects() {
			Array.prototype.forEach.call(this.$bubbles, function (elem) {
				elem.style.transition = "transform " + transitionTime + "ms ease-out";
			});

			this.addParallaxScroll();
			this.addParallaxCursor();
			this.addBrownianMotion();
		}
	}, {
		key: "addBrownianMotion",
		value: function addBrownianMotion() {
			var _this3 = this;

			// Update transition time for top/left animations
			Array.prototype.forEach.call(this.$bubbles, function (elem) {
				_this3.brownianMotion(elem);
			});
		}
	}, {
		key: "scaleUpBubbleImages",
		value: function scaleUpBubbleImages() {
			var images = this.$bubblesContainer.querySelectorAll('img');
			[].concat(_toConsumableArray(images)).forEach(function (image) {
				image.style.transform = 'scale(1) translateZ(0)';
			});
		}
	}, {
		key: "brownianMotion",
		value: function brownianMotion(elem) {
			var _this4 = this;

			var movementScale = 0.6;

			var size = parseFloat(getComputedStyle(elem, null).height.replace('px', ''));

			var moveTop = Math.floor((Math.random() * size - size) * movementScale);
			var moveLeft = Math.floor((Math.random() * size - size) * movementScale);

			var bubble = elem.querySelector('.-bubble');
			bubble.style.transform = "translate3d(" + moveLeft + "px, " + moveTop + "px, 0)";

			var transitionTime = this.getRandomBrownianTransitionTime();
			bubble.style.transition = "transform " + transitionTime + "ms cubic-bezier(0.37, 0, 0.63, 1)";
			setTimeout(function () {
				return _this4.brownianMotion(elem);
			}, transitionTime);
		}
	}, {
		key: "addParallaxCursor",
		value: function addParallaxCursor() {
			var _this5 = this;

			var movementScale = 20;
			document.addEventListener('mousemove', (0, _underscore.throttle)(function (e) {
				var centerX = window.innerWidth / 2;
				var centerY = window.innerHeight / 2;
				var percX = (e.clientX - centerX) / centerX;
				var percY = (e.clientY - centerY) / centerY;

				Array.prototype.forEach.call(_this5.$bubbles, function (elem) {
					var depth = parseInt(elem.dataset.depth, 10);
					elem.querySelector('.-cursor').style.transform = "translate3d(" + percX * depth * movementScale + "px, " + percY * depth * movementScale + "px, 0)";
				});
			}, 50));
		}
	}, {
		key: "addParallaxScroll",
		value: function addParallaxScroll() {
			var _this6 = this;

			var movementScale = 0.1;
			document.addEventListener('scroll', (0, _underscore.throttle)(function () {
				var top = window.pageYOffset;
				Array.prototype.forEach.call(_this6.$bubbles, function (elem) {
					var depth = elem.dataset.depth * movementScale;
					var movement = -(top * depth);
					elem.style.transform = "translate3d(0, " + movement + "px, 0)";
				});

				// Hide arrow?
				if (!_this6.hasScrolled) {
					_this6.$bubblesContainer.querySelector('.arrow').style.opacity = 0;
					_this6.hasScrolled = true;
				}
			}, 100));
		}
	}, {
		key: "getRandomBrownianTransitionTime",
		value: function getRandomBrownianTransitionTime() {
			var min = 2000;
			var max = 3000;
			return Math.floor(Math.random() * max + min);
		}
	}]);

	return HomeHero;
}();

exports.default = HomeHero;
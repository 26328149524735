'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.subscriptionFormInit = subscriptionFormInit;
function subscriptionFormInit() {
    var form = document.querySelector('#subscribe-form');

    function OnInput() {
        this.style.height = 'auto';
        this.style.height = this.scrollHeight + 'px';
    }

    if (form) {
        var subscribe_email = form.querySelector('#id_email_subscribe');
        var subscribe_label = form.querySelector('#id_email_subscribe_label');

        // Register when an input has been edited and add a class
        var inputs = form.querySelectorAll('input[type="email"]');
        for (var i = 0; i < inputs.length; i++) {
            inputs[i].addEventListener('change', function () {
                this.classList.add('is-changed');

                if (form.checkValidity()) {
                    form.checkValidity();
                }
            });
        }

        form.addEventListener('submit', function (event) {
            event.preventDefault();

            var form = event.target;
            var url = window.location.pathname;
            var xhr = new XMLHttpRequest();
            var formData = new FormData(event.target);

            // success case
            xhr.onreadystatechange = function () {
                if (xhr.readyState === 4) {
                    subscribe_email.remove();
                    subscribe_label.innerHTML = "Thank You";
                    try {
                        dataLayer.push({
                            'event': 'gaEvent',
                            'eventCategory': 'subscribe',
                            'eventAction': 'formsubmit',
                            'eventLabel': 'send'
                        });
                    } catch (err) {
                        console.log(err);
                    }
                }
            };

            xhr.open(form.method, url, true);
            xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
            xhr.send(formData);
        });
    }
}
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _underscore = require("underscore");

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var self = void 0;
var throttledOnScroll = function throttledOnScroll() {
  (0, _underscore.throttle)(self.init(), 100);
};

var prefersReducedMotion = function prefersReducedMotion() {
  var pref = window.matchMedia("(prefers-reduced-motion: reduce)");
  return pref.matches;
};

var Benefits = function () {
  function Benefits() {
    _classCallCheck(this, Benefits);

    if (!prefersReducedMotion()) {
      this.$container = document.getElementById("home-benefits");

      if (this.$container) {
        this.$benefitsList = document.getElementById("home-benefits-list");
        this.$benefits = this.$benefitsList.querySelectorAll("li");

        // Keep track of class 'this' for event handlers
        self = this;

        // Keep track of previous cursor position
        this.cursor = {};

        // This is half the size of the overlay defined in the CSS
        this.overlayRadius = window.innerWidth > 768 ? 150 : 100;

        // Monitor scroll and init when the block is visible
        document.addEventListener("scroll", throttledOnScroll);

        // Check init now in case the block is already visible
        this.init();
      }
    }
  }

  _createClass(Benefits, [{
    key: "init",
    value: function init() {
      var windowTop = window.scrollY + window.innerHeight;

      if (windowTop > this.$container.offsetTop) {
        // Element is visible (or above current scroll position). Init.
        // Drop scroll watcher
        document.removeEventListener("scroll", throttledOnScroll);

        // Monitor mouse movement to update overlay position
        document.addEventListener("mousemove", (0, _underscore.throttle)(this.onMouseMove, 100));

        // We need to keep track of whether the mousemove event was triggered by a pointer or touch
        this.$container.addEventListener("touchstart", function () {
          self.touch = true;
        });

        // Watch scroll
        this.watchScroll();
      }
    }
  }, {
    key: "watchScroll",
    value: function watchScroll() {
      var _this = this;

      window.addEventListener("scroll", function () {
        if (!_this.$benefitsList.classList.contains('-active')) {
          _this.$benefitsList.classList.add('-active');
        }

        _this.onScrollEnd();
      });

      this.onScrollEnd();
    }
  }, {
    key: "onMouseMove",
    value: function onMouseMove(e) {
      // Save mouse cursor position
      self.cursor.x = e.clientX;
      self.cursor.y = e.clientY;

      if (self.playingVideo && self.$overlay) {
        // If we're playing video update the overlay position
        self.updateVideoOverlayPosition();
      }

      // Reset touch monitoring
      self.touch = false;
    }
  }, {
    key: "updateVideoOverlayPosition",
    value: function updateVideoOverlayPosition() {
      var pos = {};

      if (this.cursor.x && !this.touch) {
        // Move the overlay to the last mouse cursor position
        // Offset by overlay radius so that the cursor is in the centre of the overlay
        pos.x = this.cursor.x - this.overlayRadius;
        pos.y = this.cursor.y - this.overlayRadius;
        this.$overlay.classList.remove('-touch');
      } else {
        // Center the overlay above the current benefit
        var active = this.$benefitsList.querySelector(".-active");

        if (active && active !== this.prevActive) {
          // Position overlay above current benefit
          // Only change position if the active element has changed since the last time we checked
          pos.x = window.innerWidth / 2 - this.overlayRadius;
          pos.y = active.getBoundingClientRect().top + window.scrollY - this.overlayRadius * 2;

          this.$overlay.classList.add('-touch');
          this.prevActive = active;
        }
      }

      if (pos.x && pos.y) {
        this.$overlay.style.transform = "translate3d(" + pos.x + "px, " + pos.y + "px, 0)";
      }
    }
  }, {
    key: "onScrollEnd",
    value: function onScrollEnd() {
      this.$benefitsList.classList.remove('-active');

      var halfWindowHeight = window.innerHeight / 2;
      var currentDepth = halfWindowHeight + window.scrollY;
      var benefitsStart = this.$benefitsList.offsetTop;
      var benefitsEnd = this.$benefitsList.offsetHeight + benefitsStart;

      if (currentDepth > benefitsStart && currentDepth < benefitsEnd) {
        // Current scroll depth is inside Benefits module.
        for (var i = 0; i < this.$benefits.length; i++) {
          var element = this.$benefits[i];
          var top = element.offsetTop;
          var bottom = top + element.offsetHeight;

          if (bottom > currentDepth && top < currentDepth) {
            // We've found the current benefit / active element
            // Update the active element
            this.updateActiveElement(element);

            // Break from loop: no need to consider other benefits
            break;
          }
        }
      } else {
        // Current scroll position is outside of the Benefits module

        // Hide the video overlay
        if (this.$video) {
          this.$video.style.transform = "scale(0)";
        }
        this.playingVideo = false;

        // Remove active class from existing active benefit (if exists)
        var active = this.$benefitsList.querySelector(".-active");
        if (active) {
          active.classList = "-inactive";
        }
      }
    }
  }, {
    key: "updateActiveElement",
    value: function updateActiveElement(element) {
      var _this2 = this;

      if (!element.classList.contains("-active")) {
        // Remove active class from existing active benefit (if exists)
        var active = this.$benefitsList.querySelector(".-active");
        if (active) {
          active.classList = "-inactive";
        }

        // Mark this element as Active
        element.classList = "-active";

        // Add video overlay element (if it doesn't exist)
        if (!this.$video) {
          this.addVideoOverlay();
        }

        // Update video src
        var src = element.dataset.video;
        if (src) {
          this.playingVideo = true;
          this.$video.setAttribute("src", src);
          this.$video.style.transform = "scale(1)";
          this.$video.classList = " -updated";
          setTimeout(function () {
            _this2.$video.classList = "";
          }, 500);
          this.updateVideoOverlayPosition();
        }
      }
    }
  }, {
    key: "addVideoOverlay",
    value: function addVideoOverlay() {
      this.$overlay = this.$container.querySelector('.overlay');
      this.$video = this.$overlay.querySelector('video');

      this.$overlay.setAttribute('style', '');
    }
  }, {
    key: "getScrollTop",
    value: function getScrollTop() {
      return document.documentElement && document.documentElement.scrollTop || document.body.scrollTop;
    }
  }]);

  return Benefits;
}();

exports.default = Benefits;
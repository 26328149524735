'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.logoGardenInit = logoGardenInit;
function logoGardenInit() {
    var gardens = document.querySelectorAll('.c-logo-garden');

    gardens.forEach(function (garden) {
        logoGarden(garden);
    });
}

var displayTimes = {
    min: 3000,
    max: 8000
};

function logoGarden(container) {
    var showClass = 'show';
    var logosContainer = container.querySelector('.logos');
    var gardenContainer = container.querySelector('.garden');
    var gardenLength = logosContainer.children.length < 8 ? logosContainer.children.length : 8;

    // Remove javascript not enabled warning
    gardenContainer.innerHTML = '';

    // Create garden children that will contain logos

    var _loop = function _loop(i) {
        var gardenItem = createGardenContainer();

        // Event listener for when css transition ends
        gardenItem.addEventListener('transitionend', function (event) {
            var isShowing = gardenItem.classList.contains(showClass);
            var ratioContainer = gardenItem.firstChild;

            // Change logo if there is logos to choose from
            if (!isShowing) {
                // Remove current logo
                logosContainer.appendChild(ratioContainer.firstChild);

                gardenItem.classList.add(showClass);
                ratioContainer.appendChild(getRandomImage(logosContainer));

                // Trigger change of logo
                removeClass(gardenItem, showClass);
            }
        });

        // Add initial logo and add to garden
        gardenItem.classList.add(showClass);
        gardenItem.firstChild.appendChild(getRandomImage(logosContainer));
        gardenContainer.appendChild(gardenItem);

        // Trigger change of logo
        removeClass(gardenItem, showClass);
    };

    for (var i = 0; i < gardenLength; i++) {
        _loop(i);
    }
}

function createGardenContainer() {
    var container = document.createElement('div');
    var rationContainer = document.createElement('div');

    container.classList.add('garden-item');
    rationContainer.classList.add('ratio-container');

    container.appendChild(rationContainer);

    return container;
}

function removeClass(container, className) {
    setTimeout(function () {
        container.classList.remove(className);
    }, getRandomDisplayTime());
}

function getRandomImage(container) {
    return container.children[getRandomIndex(container.children.length)];
}

function getRandomIndex(maxIndex) {
    return Math.floor(Math.random() * maxIndex);
}

function getRandomDisplayTime() {
    return Math.floor(Math.random() * displayTimes.max + displayTimes.min);
}
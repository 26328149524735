'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.sectorTabs = sectorTabs;

function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } else { return Array.from(arr); } }

function sectorTabs() {
    var container = document.querySelector('.sectors-clients');
    var tabs = container.querySelectorAll('.sectors-column .sector');
    var contents = container.querySelectorAll('.clients-column .client-list');

    function showTab() {
        var activeClass = 'active';
        var targetContentId = this.getAttribute('data-client');
        var targetContent = [].concat(_toConsumableArray(contents)).find(function (content) {
            return content.id === targetContentId;
        });

        if (targetContent) {
            // Remove active class
            [].concat(_toConsumableArray(tabs)).find(function (tab) {
                return tab.classList.contains(activeClass);
            }).classList.remove(activeClass);
            [].concat(_toConsumableArray(contents)).find(function (content) {
                return content.classList.contains(activeClass);
            }).classList.remove(activeClass);

            // Add active class to target
            this.classList.add(activeClass);
            targetContent.classList.add(activeClass);
        }
    }

    [].concat(_toConsumableArray(tabs)).forEach(function (tab) {
        tab.addEventListener('click', showTab);
    });
}
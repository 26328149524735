'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

exports.galleryInit = galleryInit;

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } else { return Array.from(arr); } }

function galleryInit() {
    var galleries = document.querySelectorAll('.c-gallery');

    [].concat(_toConsumableArray(galleries)).forEach(function (gallery) {
        var instance = new Gallery({ el: gallery });

        instance.render();
        instance.play();
    });
}

var Gallery = function () {
    function Gallery() {
        var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

        _classCallCheck(this, Gallery);

        this.el = options.el;
        this.playDelay = 7000;
        this.playInterval = null;
        this.controlsContainer = this.el.querySelector('.controls');
        this.images = [].concat(_toConsumableArray(this.el.querySelectorAll('.images .image')));
        this.controls = [].concat(_toConsumableArray(this.controlsContainer.querySelectorAll('.control')));

        if (!this.el) {
            console.warn('Gallery must have a gallery element!');
        }

        this.addEventListeners();
    }

    _createClass(Gallery, [{
        key: 'render',
        value: function render() {
            if (this.images.length > 1) {
                this.showControls();
            }
        }
    }, {
        key: 'addEventListeners',
        value: function addEventListeners() {
            var _this = this;

            this.controls.forEach(function (control) {
                control.addEventListener('click', _this.changeImage.bind(_this));
            });
        }
    }, {
        key: 'showControls',
        value: function showControls() {
            this.controlsContainer.classList.remove('hidden');
        }
    }, {
        key: 'hideControls',
        value: function hideControls() {
            this.controlsContainer.classList.add('hidden');
        }
    }, {
        key: 'getNextControl',
        value: function getNextControl() {
            var activeControl = this.controls.find(function (control) {
                return control.classList.contains('active');
            });
            return activeControl && activeControl.nextElementSibling || this.controls[0];
        }
    }, {
        key: 'clearActiveElements',
        value: function clearActiveElements() {
            var currentImage = this.images.find(function (image) {
                return image.classList.contains('active');
            });
            var currentControl = this.controls.find(function (control) {
                return control.classList.contains('active');
            });

            if (currentImage) currentImage.classList.remove('active');
            if (currentControl) currentControl.classList.remove('active');
        }
    }, {
        key: 'play',
        value: function play() {
            var startControl = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;

            this.next(startControl);
            this.loop();
        }
    }, {
        key: 'loop',
        value: function loop() {
            this.playInterval = setInterval(this.next.bind(this), this.playDelay);
        }
    }, {
        key: 'stop',
        value: function stop() {
            clearInterval(this.playInterval);
        }
    }, {
        key: 'next',
        value: function next() {
            var controlElement = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;

            var nextControl = controlElement || this.getNextControl();
            var nextImage = this.images.find(function (image) {
                return image.id === nextControl.hash.slice(1);
            });

            if (nextImage) {
                this.clearActiveElements();
                nextImage.classList.add('active');
                nextControl.classList.add('active');
            }
        }
    }, {
        key: 'changeImage',
        value: function changeImage(event) {
            event.preventDefault();

            if (!event.currentTarget.classList.contains('active')) {
                this.stop();
                this.play(event.currentTarget);
            }
        }
    }]);

    return Gallery;
}();
'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.sectorsFilter = sectorsFilter;
function sectorsFilter() {
    var nav = document.querySelector('.filter-sectors-nav');
    var openBtn = document.querySelector('.show-filter-sectors-nav');

    if (nav && openBtn) {
        var collapsedClass = 'collapsed';
        var collapsingClass = 'collapsing';
        var filterOpenClass = 'filter-nav-open';
        var overlay = nav.querySelector('.overlay');
        var closeBtn = nav.querySelector('.close-btn');
        var sectors = nav.querySelectorAll('.sectors-list a');

        // Nav transition end listener
        nav.addEventListener('transitionend', function () {
            // Add class to remove links from tab order
            if (nav.classList.contains(collapsingClass)) {
                nav.classList.add(collapsedClass);
                nav.classList.remove(collapsingClass);
            }
        });

        // Open button click listener
        openBtn.addEventListener('click', function () {
            document.body.classList.add(filterOpenClass);
            nav.classList.remove(collapsedClass, collapsingClass);
        });

        // Close button click listener
        closeBtn.addEventListener('click', function () {
            document.body.classList.remove(filterOpenClass);
            nav.classList.add(collapsingClass);
        });

        // Overlay click listener
        overlay.addEventListener('click', function () {
            document.body.classList.remove(filterOpenClass);
            nav.classList.add(collapsingClass);
        });

        sectors.forEach(function (sector) {
            // Sector link click listener
            sector.addEventListener('click', function (e) {
                document.body.classList.remove(filterOpenClass);
                nav.classList.add(collapsingClass);
            });
        });
    }
}
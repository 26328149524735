'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.contactFormInit = contactFormInit;
function checkCookie(name) {
    // Create the cookie search text
    var search = name + '=';
    // Split the cookie string into an array of individual cookies
    var cookies = document.cookie.split(';');
    // Loop through the array of cookies
    for (var i = 0; i < cookies.length; i++) {
        var c = cookies[i];
        // Trim any whitespace from the start of the cookie
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        // If the search text is found at the start of the cookie, it exists
        if (c.indexOf(search) == 0) {
            return true;
        }
    }
    // The cookie was not found
    return false;
}

function contactFormInit() {
    var container = document.querySelector('.m-contact'),
        form = container.querySelector('#contact-form');
    var submitButton = form && form.querySelector('input[type="submit"]');

    // function hideSubmitButton() {
    //     submitButton.style.visibility = 'hidden';
    // }

    // function showSubmitButton() {
    //     submitButton.style.visibility = 'visible';
    // }

    function OnInput() {
        this.style.height = 'auto';
        this.style.height = this.scrollHeight + 'px';
        form.checkValidity();

        // form.checkValidity() ? showSubmitButton() : hideSubmitButton();
    }

    function showContactForm(e) {
        var bodyClass = 'show-menu';
        var collapsedClass = 'collapsed';
        var body = document.querySelector('body');
        var toggleButton = document.querySelector('.nav-toggle');
        var parent = toggleButton.parentElement;
        var nameInput = form.querySelector('input[name="name"]');

        body.classList.remove(bodyClass);
        parent.classList.add(collapsedClass);

        container.classList.add('form-visible');

        // Make the textarea dynamic height
        // SRC: https://stackoverflow.com/questions/454202/creating-a-textarea-with-auto-resize
        var messageInput = form.querySelector('textarea');

        messageInput.setAttribute('style', 'height:' + messageInput.scrollHeight + 'px;overflow-y:hidden;');
        messageInput.addEventListener('input', OnInput, false);
        messageInput.addEventListener('change', OnInput, false);
        messageInput.addEventListener('focus', OnInput, false);
        messageInput.addEventListener('blur', OnInput, false);
    }

    if (form) {
        // const showFormButton = container.querySelector('.show-contact');
        var contactLink = document.querySelector('.contact-link');
        var anotherEnquiryButton = container.querySelector('.another-enquiry');
        var responseContainer = container.querySelector('.contact-response-holder');

        // Hide submit button
        // hideSubmitButton();
        var isCSRFToken = checkCookie('csrftoken');

        //Sometimes the csrftoken cookie is not set meaning we can't submit forms - so this is a fallback that shows a message asking to email us
        if (!isCSRFToken) {
            document.querySelector('#contact-us-email').style.display = 'block';
            return;
        }

        showContactForm();

        // Register when an input has been edited and add a class
        var inputs = form.querySelectorAll('input[type="text"], input[type="email"], textarea');
        for (var i = 0; i < inputs.length; i++) {
            inputs[i].addEventListener('change', function () {
                this.classList.add('is-changed');

                // if (form.checkValidity()) {
                //     form.checkValidity() ? showSubmitButton() : hideSubmitButton();
                // }
            });
        }

        // Hide/show the form
        contactLink.addEventListener('click', showContactForm);
        // showFormButton.addEventListener('click', showContactForm);

        form.addEventListener('submit', function (event) {
            event.preventDefault();

            var form = event.target;
            var url = window.location.pathname;
            var xhr = new XMLHttpRequest();
            var formData = new FormData(event.target);
            submitButton.value = 'Sending';

            // success case
            xhr.onreadystatechange = function () {
                if (xhr.readyState === 4) {
                    //TODO: this should be === 200 but disabling for now which we fix the CSRF issue
                    if (xhr.status) {
                        // check status xhr.status==200 or 400
                        container.classList.remove('form-visible');
                        container.classList.add('form-submitted');
                        responseContainer.innerHTML = 'Amazing! We’ll get back to you shortly.';
                        // hideSubmitButton();
                        var elem = document.createElement('img');
                        elem.setAttribute('height', '1');
                        elem.setAttribute('width', '1');

                        responseContainer.appendChild(elem);
                        elem.src = 'https://px.ads.linkedin.com/collect/?pid=2416244&conversionId=2662244&fmt=gif';

                        submitButton.value = 'Send';
                        try {
                            dataLayer.push({
                                event: 'gaEvent',
                                eventCategory: 'contact',
                                eventAction: 'formsubmit',
                                eventLabel: 'send'
                            });
                        } catch (err) {
                            console.log(err);
                        }
                    } else {
                        window.alert("That didn't work - try again. If this continues please email us at noreply@pollen.com.au");
                        submitButton.value = 'Send';
                    }
                }
            };

            // failure case
            xhr.onerror = function (jqXHR, textStatus, errorThrown) {
                submitButton.value = 'Send';
            };

            xhr.open(form.method, url, true);
            xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
            xhr.send(formData);
        });

        // Show form again
        anotherEnquiryButton.addEventListener('click', function () {
            form.reset();
            // hideSubmitButton();

            responseContainer.innerHTML = '';
            container.classList.add('form-visible');
            container.classList.remove('form-submitted');

            for (var _i = 0; _i < inputs.length; _i++) {
                inputs[_i].classList.remove('is-changed');
            }
        });
    }
}
'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

exports.testimonialsInit = testimonialsInit;

function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } else { return Array.from(arr); } }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function testimonialsInit() {
    testimonialsGallery();
}

function testimonialsGallery() {
    var galleries = document.querySelectorAll('.c-testimonials-gallery');

    galleries.forEach(function (gallery) {
        var instance = new TestimonialGallery({ el: gallery });

        instance.render();
        instance.play();
    });
}

var TestimonialGallery = function () {
    function TestimonialGallery() {
        var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

        _classCallCheck(this, TestimonialGallery);

        this.el = options.el;
        this.playDelay = 15000;
        this.playInterval = null;
        this.controlsContainer = this.el.querySelector('.controls');
        this.testimonials = [].concat(_toConsumableArray(this.el.querySelectorAll('.testimonial')));
        this.controls = [].concat(_toConsumableArray(this.controlsContainer.querySelectorAll('.control')));

        if (!this.el) {
            console.warn('Gallery must have a gallery element!');
        }

        this.addEventListeners();
    }

    _createClass(TestimonialGallery, [{
        key: 'render',
        value: function render() {
            if (this.testimonials.length > 1) {
                this.showControls();
            }

            this.responsiveBackgrounds();
        }
    }, {
        key: 'addEventListeners',
        value: function addEventListeners() {
            var _this = this;

            window.addEventListener('resize', this.responsiveBackgrounds.bind(this));

            this.controls.forEach(function (control) {
                control.addEventListener('click', _this.changeTestimonial.bind(_this));
            });
        }
    }, {
        key: 'responsiveBackgrounds',
        value: function responsiveBackgrounds() {
            this.testimonials.forEach(function (testimonial) {
                var bgImage = testimonial.querySelector('.background-image');
                var bgContainer = testimonial.querySelector('.background-container');
                var imageSrc = bgImage.currentSrc || bgImage.src;

                if (bgImage.src !== imageSrc) {
                    bgImage.src = imageSrc;
                }

                if (bgImage.src) {
                    bgContainer.style.backgroundImage = 'url("' + bgImage.src + '")';
                } else {
                    bgContainer.style.backgroundImage = 'url("' + bgImage.dataset.src + '")';
                }
            });
        }
    }, {
        key: 'showControls',
        value: function showControls() {
            this.controlsContainer.classList.remove('hidden');
        }
    }, {
        key: 'hideControls',
        value: function hideControls() {
            this.controlsContainer.classList.add('hidden');
        }
    }, {
        key: 'getNextControl',
        value: function getNextControl() {
            var activeControl = this.controls.find(function (control) {
                return control.classList.contains('active');
            });
            return activeControl && activeControl.nextElementSibling || this.controls[0];
        }
    }, {
        key: 'clearActiveElements',
        value: function clearActiveElements() {
            var currentTestimonial = this.testimonials.find(function (testimonial) {
                return testimonial.classList.contains('active');
            });
            var currentControl = this.controls.find(function (control) {
                return control.classList.contains('active');
            });

            if (currentTestimonial) currentTestimonial.classList.remove('active');
            if (currentControl) currentControl.classList.remove('active');
        }
    }, {
        key: 'play',
        value: function play() {
            var startControl = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;

            this.next(startControl);
            this.loop();
        }
    }, {
        key: 'loop',
        value: function loop() {
            this.playInterval = setInterval(this.next.bind(this), this.playDelay);
        }
    }, {
        key: 'stop',
        value: function stop() {
            clearInterval(this.playInterval);
        }
    }, {
        key: 'next',
        value: function next() {
            var controlElement = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;

            var nextControl = controlElement || this.getNextControl();
            var nextTestimonial = this.testimonials.find(function (testimonial) {
                return testimonial.id === nextControl.hash.slice(1);
            });

            if (nextTestimonial) {
                this.clearActiveElements();
                nextTestimonial.classList.add('active');
                nextControl.classList.add('active');
            }
        }
    }, {
        key: 'changeTestimonial',
        value: function changeTestimonial(event) {
            event.preventDefault();

            if (!event.currentTarget.classList.contains('active')) {
                this.stop();
                this.play(event.currentTarget);
            }
        }
    }]);

    return TestimonialGallery;
}();
'use strict';

var _vanillaLazyload = require('vanilla-lazyload');

var _vanillaLazyload2 = _interopRequireDefault(_vanillaLazyload);

var _header = require('../apps/modules/es6/header');

var _header2 = _interopRequireDefault(_header);

var _homeHero = require('../apps/modules/es6/home-hero');

var _homeHero2 = _interopRequireDefault(_homeHero);

var _navigation = require('../apps/components/es6/navigation');

var _work = require('../apps/pages/es6/work');

var _gallery = require('../apps/components/es6/gallery');

var _contact = require('../apps/modules/es6/contact');

var _subscription = require('../apps/modules/es6/subscription');

var _logoGarden = require('../apps/components/es6/logo-garden');

var _testimonials = require('../apps/components/es6/testimonials');

var _showcase = require('../apps/components/es6/showcase');

var _showcase2 = _interopRequireDefault(_showcase);

var _benefits = require('../apps/components/es6/benefits');

var _benefits2 = _interopRequireDefault(_benefits);

var _filters = require('../apps/modules/es6/filters');

var _servicesImage = require('../apps/modules/es6/services-image');

var _servicesImage2 = _interopRequireDefault(_servicesImage);

var _extendedHero = require('../apps/modules/es6/extended-hero');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } else { return Array.from(arr); } }

/**
 * Makes the last word never break by itself into a new line
 */
function orphanWord() {
    // toDo - fix adding &nbsp; inside tags
    var paragraphs = document.querySelectorAll('#content p');

    [].concat(_toConsumableArray(paragraphs)).forEach(function (paragraph) {
        var replacement = '&nbsp;';
        paragraph.innerHTML = paragraph.innerHTML.replace(/ ([^ ]*)$/, replacement + '$1');
    });
}

/**
 * Wrap each word in spans
 *
 * @param {string} text
 */
function wrapWords(text) {
    return text.split(' ').map(function (word) {
        return '<span>' + word + '</span>';
    }).join(' ');
}

/**
 * Check if all spans have the same offset.
 *
 * @param {array} spanList
 */
function isOneLine(spanList) {
    return spanList[0].offsetTop === spanList[spanList.length - 1].offsetTop;
}

/**
 * Return the last item in the first line.
 *
 * @param spanList
 * @returns {*}
 */
function getFirstLineLastWord(spanList) {
    return spanList.find(function (span, index) {
        return spanList[index + 1] && span.offsetTop < spanList[index + 1].offsetTop;
    });
}

/**
 * Return the last item in the array.
 *
 * @param {array} spanList
 * @returns {HTMLElement}
 */
function getLastLineLastWord(spanList) {
    return spanList[spanList.length - 1];
}

/**
 * Calculate the max right hand side
 *
 * @param {array} spanList
 * @returns {number}
 */
function maxRhs(spanList) {
    var maxValue = 0;

    spanList.forEach(function (span) {
        maxValue = Math.max(span.offsetLeft + span.offsetWidth, maxValue);
    });

    return maxValue;
}

/**
 * Create a line in the right position.
 *
 * It should only ever appear on the first or last line of text.
 * It should end at the furthest character in the text.
 * It should align with the centre of a lower case "e".
 * It should be the same thickness as the text.
 * It only appears when there is more than 1 line of text.
 * It should only appear when it is more than 30% width of the text.
 *
 * @param {HTMLElement} container
 * @param {array} spanList
 */
function createLine(container, spanList) {
    var maxWidth = maxRhs(spanList);
    var firstWord = getFirstLineLastWord(spanList);
    var lastWord = getLastLineLastWord(spanList);
    var firstWordRhsPos = firstWord.offsetLeft + firstWord.offsetWidth;
    var lastWordRhsPos = lastWord.offsetLeft + lastWord.offsetWidth;
    var wordForLine = Math.min(firstWordRhsPos, lastWordRhsPos);
    var rowWidth = wordForLine + maxWidth / 3;

    if (rowWidth <= maxWidth) {
        var line = document.createElement('span');
        var lineWidth = maxWidth - wordForLine - 1;

        line.className = 'balancing-line';
        line.style.width = 'calc(' + lineWidth + 'px - 0.225em)';
        line.style.backgroundColor = window.getComputedStyle(container).color;

        if (firstWordRhsPos === wordForLine) {
            firstWord.parentNode.insertBefore(line, firstWord.nextSibling);
        } else {
            lastWord.parentNode.insertBefore(line, lastWord.nextSibling);
        }
    }
}

/**
 * Add lines to end of words.
 */
function addLines() {
    var elements = [].slice.call(document.querySelectorAll('.include-line'));

    elements.forEach(function (element) {
        element.innerHTML = wrapWords(element.innerText);

        var spanList = [].slice.call(element.querySelectorAll('span'));

        if (!isOneLine(spanList)) {
            createLine(element, spanList);
        }
    });
}

/**
 * Remove lines.
 */
function removeLines() {
    var lines = document.querySelectorAll('.balancing-line');

    for (var i = 0; i < lines.length; i++) {
        lines[i].outerHTML = '';
        delete lines[i];
    }
}

/**
 * Draw lines by removing current ones and then draw new ones.
 */
function refreshLines() {
    removeLines();
    addLines();
}

document.addEventListener('DOMContentLoaded', function () {
    var body = document.querySelector('body');

    (0, _header2.default)();
    new _homeHero2.default();
    (0, _navigation.navInit)();
    (0, _contact.contactFormInit)();
    (0, _subscription.subscriptionFormInit)();
    (0, _extendedHero.extendedHero)();
    (0, _extendedHero.extendedHeroBgScroll)();
    (0, _extendedHero.capabilityHeroBgScroll)();

    (0, _logoGarden.logoGardenInit)();
    new _benefits2.default();
    (0, _filters.sectorsFilter)();
    new _showcase2.default();

    if (body.classList.contains('work-page') || body.classList.contains('home-page')) {
        (0, _work.sectorTabs)();
    }

    if (body.classList.contains('service-page')) {
        new _servicesImage2.default();
    }

    var lazyVideos = [].slice.call(document.querySelectorAll('video.lazy'));

    if ('IntersectionObserver' in window) {
        var lazyVideoObserver = new IntersectionObserver(function (entries, observer) {
            entries.forEach(function (video) {
                if (video.isIntersecting) {
                    for (var source in video.target.children) {
                        var videoSource = video.target.children[source];
                        if (typeof videoSource.tagName === 'string' && videoSource.tagName === 'SOURCE') {
                            videoSource.src = videoSource.dataset.src;
                        }
                    }

                    video.target.load();
                    video.target.classList.remove('lazy');
                    lazyVideoObserver.unobserve(video.target);
                }
            });
        });

        var lazyWorkImages = [].slice.call(document.querySelectorAll('a.lazy-image'));

        var backGroundImageObserver = new IntersectionObserver(function (entries, observer) {
            entries.forEach(function (image) {
                if (image.isIntersecting) {
                    image.target.classList.add(image.target.dataset.imageclass);
                    image.target.classList.remove('lazy-image');
                    backGroundImageObserver.unobserve(image.target);
                }
            });
        });

        lazyVideos.forEach(function (lazyVideo) {
            lazyVideoObserver.observe(lazyVideo);
        });

        lazyWorkImages.forEach(function (lazyWorkImage) {
            backGroundImageObserver.observe(lazyWorkImage);
        });
    }

    (0, _testimonials.testimonialsInit)();
});

window.addEventListener('load', function () {
    (0, _gallery.galleryInit)();
    addLines();
});

window.addEventListener('resize', function () {
    refreshLines();
});

var lazyLoad = new _vanillaLazyload2.default({});
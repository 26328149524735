'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

exports.default = headerInit;

var _underscore = require('underscore');

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function headerInit() {
    var header = new Header();
    var throttleToggle = (0, _underscore.throttle)(header.toggleOnScroll.bind(header), 300);

    window.addEventListener('scroll', throttleToggle);
}

var Header = function () {
    function Header() {
        var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

        _classCallCheck(this, Header);

        this.el = document.querySelector('header.m-header');
        this.height = this.el.clientHeight;
        this.scrolledPosition = this.height;
    }

    _createClass(Header, [{
        key: 'hide',
        value: function hide() {
            this.el.classList.add(Header.hidden_class);
        }
    }, {
        key: 'show',
        value: function show() {
            this.el.classList.remove(Header.hidden_class);
        }
    }, {
        key: 'scrolled',
        value: function scrolled() {
            this.el.classList.add(Header.scrolled_class);
        }
    }, {
        key: 'notScrolled',
        value: function notScrolled() {
            this.el.classList.remove(Header.scrolled_class);
        }
    }, {
        key: 'toggleOnScroll',
        value: function toggleOnScroll() {
            var newScrollPosition = window.scrollY;

            newScrollPosition > 0 ? this.scrolled() : this.notScrolled();
            newScrollPosition > this.scrolledPosition ? this.hide() : this.show();
            this.scrolledPosition = newScrollPosition > this.height ? newScrollPosition : this.height;
        }
    }], [{
        key: 'hidden_class',
        get: function get() {
            return 'hidden';
        }
    }, {
        key: 'scrolled_class',
        get: function get() {
            return 'scrolled';
        }
    }]);

    return Header;
}();